<template>
  <AutoComplete
    v-model="model"
    field="acctname"
    inputClass="form-control"
    :suggestions="values"
    :dropdown="false"
    placeholder="Ingrese la busqueda..."
    :class="classes"
    :disabled="disabled"
    @item-select="onChange"
    @complete="onComplete($event)"
  >
    <template #item="{ item }">
      <div>
        <span>{{ item.acctcode }} - {{ item.acctname }}</span>
      </div>
    </template>
  </AutoComplete>
</template>

<script>
import { ref, watch } from 'vue'
import searchAccountService from '../../../../../services/searchAccountService'

export default {
  name: 'AutocompleteAccount',
  props: {
    modelValue: {
      type: [String, Number],
      default: null
    },
    classes: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, context) {
    const values = ref([])
    const model = ref({
      acctcode: '',
      acctname: ''
    })
    /**/
    const onComplete = ({ query }) => {
      return searchAccountService({ value: query.trim() }).then(({ data }) => {
        values.value = data
      }).catch(err => {
        console.error(err.message)
      })
    }
    /**/
    const onChange = ({ value }) => {
      context.emit('update:modelValue', value.acctcode)
      context.emit('getAccountName', value.acctname)
    }
    /**/
    const setAccount = () => {
      values.value = []
      model.value = { acctcode: '', acctname: '' }
      if (props.modelValue) {
        searchAccountService({ value: props.modelValue }).then(({ data }) => {
          values.value = data
          model.value = data[0]
          context.emit('update:modelValue', props.modelValue)
        }).catch(err => {
          console.error(err.message)
        })
      }
    }
    /**/
    watch(() => props.modelValue, (value) => {
      setAccount()
    })

    return {
      model,
      values,
      onComplete,
      onChange
    }
  }
}
</script>

<style scoped>

</style>
