<template>
  <AutoComplete
    v-model="model"
    field="code"
    inputClass="form-control"
    :suggestions="values"
    :dropdown="false"
    placeholder="Ingrese la busqueda..."
    :class="classes"
    :disabled="disabled"
    @item-select="onChange"
    @complete="onComplete($event)"
  >
    <template #item="{ item }">
      <div>
        <span>{{ item.code }} - {{ item.name }}</span>
      </div>
    </template>
  </AutoComplete>
</template>

<script>
import { ref, watch } from 'vue'
import searchTaxService from '../../../../../services/searchTaxService'

export default {
  name: 'AutocompleteTax',
  props: {
    modelValue: {
      type: [String, Number],
      default: null
    },
    classes: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    _key: {
      type: [String, Number],
      default: null
    }
  },
  setup (props, context) {
    const values = ref([])
    const model = ref({
      code: '',
      name: ''
    })
    /**/
    const onComplete = ({ query }) => {
      return searchTaxService({ value: query.trim() }).then(({ data }) => {
        values.value = data
      }).catch(err => {
        console.error(err.message)
      })
    }
    /**/
    const onChange = ({ value }) => {
      context.emit('update:modelValue', value.code)
      context.emit('getTaxRate', { _key: props._key, value: value.rate })
    }
    /**/
    const setAccount = () => {
      console.log('si....')
      values.value = []
      model.value = { code: '', name: '' }
      if (props.modelValue) {
        searchTaxService({ value: props.modelValue }).then(({ data }) => {
          values.value = data
          model.value = data[0]
          context.emit('update:modelValue', props.modelValue)
        }).catch(err => {
          console.error(err.message)
        })
      }
    }
    /**/
    watch(() => props.modelValue, (value) => {
      setAccount()
    })

    return {
      model,
      values,
      onComplete,
      onChange
    }
  }
}
</script>

<style scoped>

</style>
