<template>
  <form action="" @submit.prevent="onSubmit">
    <div class="box w-full mb-4">
      <div class="p-2">
        <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-4">
          <div class="lg:col-span-4">
            <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
              <div class="mb-1">
                <label for="requestDate">Fecha de contabilización</label>
                <input type="date" class="form-control" v-model="modelo.DocDate">
                <messageError :text="errors.DocDate" />
              </div>
              <div class="md:col-span-2">
                <label for="banco">Proveedor:</label>
                <AutocompleteProveedores
                  v-model="modelo.CardName"
                  :classes="{
                    'w-full': true,
                    'mt-1': true,
                    'rounded': true,
                    'p-invalid': errors.CardCode
                  }"
                  :disabled="false"
                  :supplierType="itemSelected.typeProvider"
                  @setSupplier="onSetSupplier"
                />
                <input type="hidden" v-model="modelo.CardName">
                <MessageError :text="errors.CardCode"/>
              </div>
              <div class="md:col-span-1">
                <label for="NumAtCard">N° Ref. acreedor:</label>
                <InputText
                  v-model="modelo.NumAtCard"
                  id="NumAtCard"
                  type="text"
                  placeholder="N° Ref. acreedor"
                  class="h-10 border mt-1 rounded w-full"
                  :class="{ 'p-invalid': errors.NumAtCard }"
                />
                <MessageError :text="errors.NumAtCard"/>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="box w-full mb-4">
      <div class="p-2">
        <div class="flex justify-between items-center">
           <span class="text-sm font-bold capitalize p-2">
            </span>
          <Button
            :icon="'pi pi-plus'"
            class="p-button-rounded p-button-xs"
            :class="'p-button-success'"
            :Tooltip="'Agregar Item'"
            @click="onAddProduct()"
            :disabled="itemSelected.typeProvider == 1 ? false : true"
          />
        </div>
        <div class="p-flex">
          <DataTable
          ref="dt"
          :lazy="true"
          :value="modelo.articulos"
          class="p-datatable-sm p-2 text-sm"
          dataKey="id"
          responsiveLayout="stack"
          breakpoint="960px"
          :scrollable="true"
          scrollHeight="300px"
        >
          <!-- Columnas -->
          <Column field="ItemDescription" header="Descripción">
            <template #body="{ data }" class="flex flex-col">
              <div class="p-inputgroup flex flex-col">
                <input
                  v-model="data.ItemDescription"
                  type="text"
                  step="any"
                  class="form-control"
                  :disabled="itemSelected.typeProvider == 1 ? false : true"
                >
                <messageError :text="errors[`articulos[${data._key}].ItemDescription`]"/>
              </div>
            </template>
          </Column>
          <Column field="Quantity" header="Cant."  style="max-width: 6rem">
            <template #body="{ data }">
              <div class="p-inputgroup flex flex-col">
                <input
                  v-model="data.Quantity"
                  type="number"
                  step="any"
                  class="form-control"
                  @keyup="onTotalVlr(data._key)"
                  :disabled="itemSelected.typeProvider == 1 ? false : true"
                >
                <messageError :text="errors[`articulos[${data._key}].Quantity`]"/>
              </div>
            </template>
          </Column>
          <Column field="UnitPrice" header="Precio Unit.">
            <template #body="{ data }">
              <div class="p-inputgroup flex flex-col">
                <input
                  v-model="data.UnitPrice"
                  type="number"
                  step="any"
                  class="form-control"
                  @keyup="onTotalVlr(data._key)"
                  :disabled="itemSelected.typeProvider == 1 ? false : true"
                >
                <messageError :text="errors[`articulos[${data._key}].UnitPrice`]"/>
              </div>
            </template>
          </Column>
          <Column field="DiscountPercent" header="% Desc." style="max-width: 6rem">
            <template #body="{ data }">
              <div class="p-inputgroup flex flex-col">
                <input
                  v-model="data.DiscountPercent"
                  type="number"
                  step="any"
                  class="form-control"
                  @keyup="onTotalVlr(data._key)"
                  :disabled="itemSelected.typeProvider == 1 ? false : true"
                >
                <messageError :text="errors[`articulos[${data._key}].DiscountPercent`]"/>
              </div>
            </template>
          </Column>
          <Column field="TaxCode" header="Indicar de Imp.">
            <template #body="{ data }">
              <div class="p-inputgroup">
              <AutocompleteTax
                v-model="data.TaxCode"
                :_key="data._key"
                :classes="{
                  'w-full': true,
                  'mt-1': true,
                  'rounded': true,
                  'p-invalid': errors[`articulos[${data._key}].TaxCode`],
                }"
                @getTaxRate="onGetTaxtRate"
                @blur="onTotalVlr(data._key)"
              />
              <input type="hidden" v-model="data.TaxPercentagePerRow" @change="onTotalVlr(data._key)">
              <messageError :text="errors[`articulos[${data._key}].TaxCode`]"/>
              </div>
            </template>
          </Column>
          <Column field="total" header="Total (ML)" style="max-width: 8rem">
            <template #body="{ data }">
              <div class="p-inputgroup">
                <input
                  v-model="data.total"
                  type="number"
                  step="any"
                  class="form-control"
                  :disabled="itemSelected.typeProvider == 1 ? false : true"
                >
              </div>
            </template>
          </Column>
          <Column field="AccountCode" header="Cuenta Mayor.">
            <template #body="{ data }">
              <div class="flex flex-col">
              <AutocompleteAccount
                v-model="data.AccountCode"
                :classes="{
                    'w-full': true,
                    'mt-1': true,
                    'rounded': true,
                    'p-invalid': errors.AccountCode
                  }"
                :disabled="itemSelected.typeProvider == 1 ? false : true"
              />
              <messageError :text="errors[`articulos[${data._key}].AccountCode`]"/>
              </div>
            </template>
          </Column>
          <Column field="CostingCode" header="Norma reparto">
            <template #body="{ data }">
              <div class="flex flex-col">
              <AutocompleteNorma
                v-model="data.CostingCode"
                :classes="{
                    'w-full': true,
                    'mt-1': true,
                    'rounded': true,
                    'p-invalid': errors.CostingCode
                  }"
                :disabled="itemSelected.typeProvider == 1 ? false : true"
              />
              <messageError :text="errors[`articulos[${data._key}].CostingCode`]"/>
                </div>
            </template>
          </Column>
          <Column field="WtLiable" header="Sujeto a retención Imp." style="max-width: 8rem">
            <template #body="{ data }">
              <div class="flex flex-col">
                <Dropdown id="WtLiable" v-model="data.WtLiable" :options="WtLiableTypes" :disabled="supplier.wtliable === 'N' ? true : false" optionLabel="name" optionValue="id" placeholder="Seleccione"
                          :class="{ 'p-invalid': errors.WtLiable }"
                />
                <messageError :text="errors[`articulos[${data._key}].WtLiable`]"/>
              </div>
            </template>
          </Column>
          <Column field="_key" header="Acciones" style="max-width: 8rem;" headerStyle="width: 8em" bodyStyle="text-align: center">
            <template #body="{ data }">
              <Button
                :icon="'pi pi-times'"
                class="p-button-rounded p-button-xs ml-4"
                :class="'p-button-danger'"
                :Tooltip="'Eliminar'"
                @click="onDeleteProduct(data._key)"
                :disabled="modelo.articulos.length === 1"
              />
            </template>
          </Column>
        </DataTable>
        </div>
        <br>
        <div class="p-grid">
          <div class="p-col">
            <div class="md:col-span-1">
              <label for="Comments">Comentarios: </label>
              <Textarea
                v-model="modelo.Comments"
                id="Comments"
                :class="{ 'p-invalid': errors.Comments }"
                :autoResize="true"
                rows="2"
                class="h-10 border mt-1 rounded w-full"
                placeholder="Comentarios"
              />
              <MessageError :text="errors.Comments" />
            </div>
          </div>
          <div class="md:col-span-1">
          </div>
          <div class="md:col-span-1">
            <Card style="width: 25rem; background: #F3F7FF 0% 0% no-repeat padding-box; border-radius: 7px; opacity: 1;">
              <template #content>
                <div class="w-full flex my-1">
                  <div class=" w-full text-gray-600">Total antes del descuento</div>
                  <div class="text-gray-600 text-right" style="min-width: 120px; width: 120px;">{{$h.formatCurrency(totales.importe)}}</div>
                </div>
                <div class="w-full flex my-1">
                  <div class=" w-full text-gray-600">Retenciones</div>
                  <div class="text-gray-600 text-right" style="min-width: 120px; width: 120px;">{{$h.formatCurrency(totales.WtLiable)}}</div>
                </div>
                <div class="w-full flex my-1">
                  <div class=" w-full text-gray-600">Impuesto</div>
                  <div class="text-gray-600 text-right" style="min-width: 120px; width: 120px;">{{$h.formatCurrency(totales.tax)}}</div>
                </div>
                <div class="w-full flex my-1">
                  <div class=" w-full text-gray-600">Total del documento</div>
                  <div class="text-gray-600 text-right" style="min-width: 120px; width: 120px;">{{$h.formatCurrency(totales.total)}}</div>
                </div>
              </template>
            </Card>
          </div>
        </div>
      </div>
    </div>
    <Button label="Cerrar" icon="pi pi-times" @click="closeForm" class="p-button-xs p-button-danger"/>
    <Button label="Guardar" icon="pi pi-check" @click="onSubmit" class="p-button-xs p-button-success ml-2" autofocus/>

    <Dialog v-model:visible="displayModalWTLiable"
            :breakpoints="{'960px': '95vw'}"
            :style="{width: '60vw', 'z-index': 1000}">
      <DataTable :value="WTLiable"
                 ref="dt2"
                 :lazy="true"
                 class="p-datatable-sm p-2 text-sm"
                 dataKey="id2"
                 responsiveLayout="stack"
                 breakpoint="960px">
        <Column field="wtcode" header="Codigo"></Column>
        <Column field="wtname" header="Nombre"></Column>
        <Column field="rate" header="Tasa"></Column>
        <Column field="prctbsamnt" header="Importe base">
          <template #body="{ data }">
            {{ $h.formatCurrency(( data.rate/100) * totales.baseWt) }}
          </template>
        </Column>
        <Column field="applyWt" header="Aplicar">
          <template #body="{ data }">
            <Checkbox id="binary" v-model="data.applyWt" :binary="true" @change="calculateWt(data)" />
          </template>
        </Column>
        <Column field="quantity" header="Retención">
          <template #body="{ data }">
            {{  $h.formatCurrency(data.valorWt)  }}
          </template>
        </Column>
      </DataTable>
      <Button label="Cancelar" icon="pi pi-times" @click="displayModalWTLiable = false" class="p-button-xs p-button-danger"/>
      <Button label="Guardar" icon="pi pi-check" @click="onSubmit" class="p-button-xs p-button-success" autofocus/>
    </Dialog>
  </form>

</template>
<script>
import { array, date, number, object, string } from 'yup'
import { useField, useForm } from 'vee-validate'
import AutocompleteProveedores from '../proveedores'
import AutocompleteAccount from '../account'
import AutocompleteNorma from '../norma'
import AutocompleteTax from '../tax'
import storeInvoicingSapService from '../../../../../services/storeInvoicingSapService'
import getPedidoService from '../../../../../services/getPedidoService'
import getRetencionesService from '../../../../../services/getRetencionesService'
import { onMounted, ref, watch } from 'vue'
import { success, error } from '../../../../../../../../libs/mensajes'
import dayjs from 'dayjs'
import { useLoading } from 'vue3-loading-overlay'
export default {
  name: 'modalForm',
  components: {
    AutocompleteProveedores,
    AutocompleteAccount,
    AutocompleteNorma,
    AutocompleteTax
  },
  props: {
    itemSelected: { type: Object, default: () => {} }
  },
  setup (props, context) {
    const totales = ref({
      importe: 0,
      baseWt: 0,
      WtLiable: 0,
      tax: 0,
      discount: 0,
      total: 0
    })
    const loader = useLoading()
    const supplier = ref({})
    const WtLiableTypes = ref([
      { id: 'Y', name: 'Si' },
      { id: 'N', name: 'No' }
    ])
    const displayModalWTLiable = ref(false)
    const WTLiable = ref([])
    const validationSchema = object().shape({
      DocDate: date().required().nullable().label('Fecha'),
      CardCode: string().required().nullable().label('Proveedor'),
      CardName: string().required().nullable().label('Proveedor'),
      NumAtCard: string().nullable().label('N° Ref. acreedor:'),
      Comments: string().required().nullable().label('Comentarios'),
      articulos: array()
        .of(
          object().shape({
            ItemDescription: string().required().nullable().label('Descripción'),
            Quantity: number().min(0).integer().required().nullable().label('cantidad del item'),
            UnitPrice: number().min(0).required().nullable().label('Precio unitario'),
            DiscountPercent: number().min(0).required().nullable().label('Porcentaje de descuento'),
            TaxCode: string().nullable().label('Impuesto'),
            AccountCode: string().required().nullable().label('Cuenta Mayor'),
            CostingCode: string().nullable().label('Norma reparto'),
            WtLiable: string().required().nullable().label('Sujeto a retención Imp')
          })
        )
        .min(1).label('Producto')
    })
    const { values: modelo, handleSubmit, handleReset, errors } = useForm({
      validationSchema
    })

    useField('DocDate', null, { initialValue: dayjs().format('YYYY-MM-DD') })
    useField('typeProvider', null, { initialValue: props.itemSelected.typeProvider })
    useField('CardCode', null, { initialValue: props.itemSelected.providerCode })
    useField('CardName', null, { initialValue: props.itemSelected.providerName })
    useField('NumAtCard', null, { initialValue: props.itemSelected.documentNumber })
    useField('CntctCode', null, { initialValue: '' })
    useField('Comments', null, { initialValue: '' })
    useField('articulos', null, {
      initialValue: [{
        BaseEntry: null,
        BaseType: null,
        BaseLine: null,
        ItemCode: null,
        id: null,
        _key: 0,
        ItemDescription: '',
        Quantity: 1,
        DiscountPercent: 0.0,
        UnitPrice: 0,
        total: 0,
        TaxCode: '',
        TaxPercentagePerRow: 0,
        AccountCode: '',
        WtLiable: supplier.value.wtliable,
        CostingCode: ''
      }]
    })
    useField('WithholdingTaxDataCollection', null, { initialValue: [] })
    const onSubmit = handleSubmit((values) => {
      if (displayModalWTLiable.value === false) {
        preOnSubmit()
        return false
      }
      let infoValues = {}
      if (props.itemSelected.typeProvider !== 1) {
        infoValues = {
          DocDate: values.DocDate,
          CardCode: values.CardCode,
          DocumentLines: values.articulos
        }
      } else {
        infoValues = values
        infoValues.DocType = 'dDocument_Service'
      }

      infoValues.DocumentLines = values.articulos.map((item) => {
        delete item.id
        delete item._key
        delete item.total
        if (props.itemSelected.typeProvider !== 1) {
          return {
            BaseEntry: item.BaseEntry,
            BaseType: item.BaseType,
            BaseLine: item.BaseLine,
            ItemCode: item.ItemCode,
            TaxCode: item.TaxCode
          }
        }
        return item
      })
      infoValues.WithholdingTaxDataCollection = []
      WTLiable.value.forEach(element => {
        if (element.applyWt) {
          infoValues.WithholdingTaxDataCollection.push({
            WTCode: element.wtcode
          })
        }
      })
      loader.show({
        'z-index': 9000
      })
      storeInvoicingSapService(infoValues).then(({ data }) => {
        loader.hide()
        success('Documento registrado exitosamente')
        context.emit('onCompleSubmit', { DocNum: data.DocNum, DocEntry: data.DocEntry })
        handleReset()
      }).catch((err) => {
        loader.hide()
        if (err.response.status) {
          error(err.response.data.message)
        }
      })
    })

    /* add product detail  */
    const onAddProduct = () => {
      modelo.articulos.push({
        id: null,
        _key: modelo.articulos.length,
        ItemDescription: '',
        Quantity: 0,
        DiscountPercent: 0.0,
        UnitPrice: 0,
        total: 0,
        TaxCode: '',
        TaxPercentagePerRow: 0,
        AccountCode: '',
        WtLiable: supplier.value.wtliable,
        CostingCode: ''
      })
    }
    /* delete product detail */
    const onDeleteProduct = (_key) => {
      modelo.articulos.splice(modelo.articulos.findIndex(element => element._key === _key), 1)
    }

    /* get account name */
    const onGetTaxtRate = ({ _key, value }) => {
      modelo.articulos[_key].TaxPercentagePerRow = value
    }

    /* Calculation of values for each detail and totals. */
    const onTotalVlr = (_key) => {
      modelo.articulos[_key].importe = (modelo.articulos[_key].UnitPrice * modelo.articulos[_key].Quantity)
      modelo.articulos[_key].dicountValue = modelo.articulos[_key].importe * modelo.articulos[_key].DiscountPercent / 100
      modelo.articulos[_key].total = (modelo.articulos[_key].importe - modelo.articulos[_key].dicountValue) + ((modelo.articulos[_key].importe - modelo.articulos[_key].dicountValue) * (modelo.articulos[_key].TaxPercentagePerRow / 100))
      totales.value.importe = 0
      totales.value.tax = 0
      totales.value.total = 0
      for (const articulo of modelo.articulos) {
        totales.value.importe += articulo.importe - articulo.dicountValue
        totales.value.discount += articulo.dicountValue
        totales.value.tax += (articulo.importe - articulo.dicountValue) * (articulo.TaxPercentagePerRow / 100)
      }
      totales.value.total = totales.value.importe + totales.value.tax
    }

    /* Obtener nombre proveedor */
    const onSetSupplier = (value) => {
      console.log(value)
      modelo.CardCode = value.cardcode
      modelo.CardName = value.cardname
      supplier.value = value
      getRetencionesSupplier()
    }
    const getRetencionesSupplier = () => {
      if (modelo.CardCode) {
        getRetencionesService({ value: modelo.CardCode }).then(({ data }) => {
          WTLiable.value = data
          WTLiable.value.map(element => {
            element.applyWt = false
            element.valorWt = 0
          })
        })
      }
    }
    const sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms))
    }

    const getPedido = () => {
      if (props.itemSelected.typeProvider !== 1) {
        getPedidoService({ value: props.itemSelected.documentNumber, cardcode: props.itemSelected.providerCode }).then(async ({ data }) => {
          modelo.Comments = data.comments
          modelo.articulos = []
          for (const item of data.articulos) {
            onAddProduct()
            await sleep(5)
            modelo.articulos[modelo.articulos.length - 1].BaseEntry = data.DocEntry
            modelo.articulos[modelo.articulos.length - 1].BaseType = data.ObjType
            modelo.articulos[modelo.articulos.length - 1].BaseLine = item.LineNum
            modelo.articulos[modelo.articulos.length - 1].ItemCode = item.ItemCode
            modelo.articulos[modelo.articulos.length - 1].ItemDescription = item.Dscription
            modelo.articulos[modelo.articulos.length - 1].Quantity = item.Quantity
            modelo.articulos[modelo.articulos.length - 1].DiscountPercent = 0.0
            modelo.articulos[modelo.articulos.length - 1].UnitPrice = item.Price
            modelo.articulos[modelo.articulos.length - 1].total = 0
            modelo.articulos[modelo.articulos.length - 1].TaxCode = item.taxcode
            modelo.articulos[modelo.articulos.length - 1].TaxPercentagePerRow = 0
            modelo.articulos[modelo.articulos.length - 1].AccountCode = item.AcctCode
            modelo.articulos[modelo.articulos.length - 1].WtLiable = supplier.value.wtliable
            modelo.articulos[modelo.articulos.length - 1].CostingCode = ''

            onTotalVlr(modelo.articulos.length - 1)
          }
        })
      }
    }

    /* Pre onSubmit save WtLiable */
    const preOnSubmit = () => {
      totales.value.baseWt = 0
      for (const articulo of modelo.articulos) {
        if (articulo.WtLiable === 'Y') {
          totales.value.baseWt += articulo.importe - articulo.dicountValue
        }
      }
      displayModalWTLiable.value = true
      return false
    }

    /* Calculation of WtLiable. */
    const calculateWt = (data) => {
      onTotalVlr(modelo.articulos.length - 1)
      totales.value.WtLiable = 0
      WTLiable.value.map(element => {
        if (element.applyWt) {
          element.valorWt = ((element.rate / 100) * totales.value.baseWt) * (element.prctbsamnt / 100)
          totales.value.WtLiable += element.valorWt
        } else {
          element.valorWt = 0
        }
      })
      totales.value.total -= totales.value.WtLiable
    }

    const closeForm = () => {
      context.emit('onCloseForm')
    }
    /* if change the supplier, change the  wtliable in the detaiil */
    watch(supplier, (supplier) => {
      modelo.articulos.map((element) => {
        element.WtLiable = supplier.wtliable
        return element
      })
    })

    onMounted(() => {
      getPedido()
      getRetencionesSupplier()
    })

    return {
      modelo,
      errors,
      onSubmit,
      totales,
      displayModalWTLiable,
      WTLiable,
      WtLiableTypes,
      supplier,
      closeForm,
      preOnSubmit,
      calculateWt,
      onAddProduct,
      onGetTaxtRate,
      onTotalVlr,
      onSetSupplier,
      onDeleteProduct
    }
  }
}
</script>
